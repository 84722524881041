// jshint esversion: 6

PIKE.tablist = function($) {

  const Tablist = function(ul) {
    const my = this;

    this.ul = $(ul);
    this.lis = this.ul.find('li');
    this.tabs = this.ul.find('[role=tab]');

    this.lis.each(function(i, el) {
      el = $(el);
      if (!el.hasClass('active')) {
        let c = el.find('[role=tab]').attr('aria-selected', false).attr('aria-controls');
        $('#' + c).attr('aria-hidden', true);
      }
    });

    const swapTab = function(which) {
      // window.console.log('Switch tab to: ', which);
      const cur = my.ul.find('[aria-selected=true]');
      const newt = my.ul.find('[aria-controls=' + which + ']');

      if (newt.length === 0) {
        // window.console.log('Tab not found!');
        return;
      }

      if (cur.length) {
        let curb = cur.attr('aria-controls');

        if (curb === which) {
          // window.console.log('Already selected');
          return false;
        }
      }

      const cur_target = $('[id=' + cur.attr('aria-controls') + ']');
      const new_target = $('[id=' + newt.attr('aria-controls') + ']');

      cur.closest('li').removeClass('active');
      cur.attr('aria-selected', false);
      cur_target.attr('aria-hidden', true);
      newt.closest('li').addClass('active');
      newt.attr('aria-selected', true);
      new_target.attr('aria-hidden', false);
    };

    this.tabs.on('click', function(e) {
      swapTab($(this).attr('aria-controls'));
      return false;
    });
  };

  $('[role="tablist"]').each((i, el) => {
    new Tablist(el);
  });

  return this;
};
