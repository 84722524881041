// jshint esversion: 6

PIKE.screenResizeEvents = ($, ...funcs) => {
  'use strict';
  const debounceTime = 200;
  const efficientFn = PIKE.debounce(() => {
    window.requestAnimationFrame(() => {
      funcs.forEach(func => { func($); });
    });
  }, debounceTime);
  $(window).on('resize', efficientFn);
};
