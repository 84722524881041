// jshint esversion: 6

PIKE.highcharts = function($) {
  'use strict';

  const hs = PIKE.highcharts;
  const defaultOptions = {
    chart: {
      backgroundColor: 'transparent',
      reflow: true,
      spacing: [0, 0, 0, 0]
    },
    colors: [
      '#003e69',
      '#005f90',
      '#007ab9',
      '#0087cc',
      '#008cd4',
      '#00abeb'
    ],
    title: {
      align: 'left',
      margin: 30,
      style: {
        fontSize: '2.2rem',
        fontWeight: 'bold'
      }
    }
  };

  let isLoaded = false,
    onLoadQueue = [];

  let load = (callback) => {
    if (document.getElementById('__highcharts__')) {
      if (isLoaded) {
        if (callback) {
          callback();
        }
        return;
      }

      onLoadQueue.push(callback);

      return;
    }

    onLoadQueue.push(callback);

    let s = document.createElement('script');
    s.id = '__highcharts__';
    s.src = '/assets/js/local/highcharts.min.js';
    s.async = true;

    s.addEventListener('load', () => {
      isLoaded = true;
      Highcharts.setOptions(defaultOptions);
      onLoadQueue.forEach((f) => {
        f();
      });
    }, true);

    document.head.appendChild(s);
  };

  hs.init = (callback) => {
    load(() => {
      if (callback) {
        callback();
      }
    });
  };

  hs.commits = (target, data, title) => {
    load(() => {
      let tmpSeries = {}, series = [],
        lowDate, highDate;

      data.forEach(r => {
        let name = r.committer_name;
        let tdate = new Date(Date.parse(r.day));

        if (!lowDate || tdate < lowDate) {
          lowDate = tdate;
        }

        if (!highDate || tdate > highDate) {
          highDate = tdate;
        }

        if (!tmpSeries[name]) {
          tmpSeries[name] = {};
        }

        if (!tmpSeries[name][tdate]) {
          tmpSeries[name][tdate] = 0;
        }

        tmpSeries[name][tdate] += 1;
      });

      let days = (highDate.getTime() - lowDate.getTime()) / (1000*3600*24) + 1;
      let tmpl = {};
      let prevDate = new Date(lowDate.getTime());
      tmpl[lowDate] = 0;
      tmpl[highDate] = days;

      let tmpDate;
      let categories = [ lowDate.format('%Y-%m-%d') ];

      for (var i = 1; i < days; i++) {
        prevDate.setTime(prevDate.getTime() + (3600*1000)*24);
        tmpDate = new Date(prevDate.getTime());
        tmpl[tmpDate] = i;
        categories.push(tmpDate.format('%Y-%m-%d'));
      }

      categories.push(highDate.format('%Y-%m-%d'));

      Object.keys(tmpSeries).forEach(k => {
        let data = Array.allocate(days, null);

        Object.keys(tmpSeries[k]).forEach(kk => {
          data[tmpl[kk]] = [ new Date(Date.parse(kk)), tmpSeries[k][kk] ];
        });

        // data = data.sort((a, b) => {
        //   if (!a && !b) return 0;
        //   if (a && !b)  return 1;
        //   if (b && !a)  return -1;

        //   if (a[0].getTime() > b[0].getTime()) {
        //     return 1;
        //   }
        //   if (a[0].getTime() < b[0].getTime()) {
        //     return -1;
        //   }
        //   return 0;
        // });

        series.push({
          name: k,
          data: data
        });
      });

      const localConf = {
        chart: {
          type: 'column'
        },
        title: {
          text: title === undefined ? 'Recent commits (master branch)' : title
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of commits'
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
              style: {
                textShadow: '0 0 3px black'
              }
            }
          }
        },
        series: series
      };

      if (title === null) {
        localConf.chart.margin = null;
        localConf.chart.marginTop = 10;
      }

      $(target).highcharts(localConf);
    });
  };

  hs.commitActivity = (target, data, title) => {
    load(() => {
      let categories = [];

      let d = data.map((x) => {
        var date = new Date(x.week*1000);
        categories.push(date.getWeek() + ' ' + date.getFullYear());
        return x.total;
      });

      $(target).highcharts({
        chart: {
          type: 'column'
        },
        title: {
          text: title === undefined ? 'Past year of weekly commits' : title
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of commits'
          }
        },
        series: [
          { name : 'Commits',
            data: d }
        ]
      });
    });
  };

  hs.codeFrequency = (target, data, title) => {
    load(() => {
      let categories = [],
        additions = [],
        deletions = [];

      data.forEach((x) => {
        additions.push(x[1]);
        deletions.push(x[2]);
        var date = new Date(x[0]*1000);
        categories.push(date.getWeek() + ' ' + date.getFullYear());
      });

      const localConf = {
        chart: {
          type: 'column',
          zoomType: 'xy'
        },
        title: {
          text: title === undefined ? 'Additions/Deletions' : title
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          title: {
            text: 'Lines of code'
          }
        },
        series: [
          { name : 'Additions', data: additions },
          { name : 'Deletions', color: '#a00', data: deletions }
        ]
      };

      if (title === null) {
        localConf.chart.margin = null;
        localConf.chart.marginTop = 10;
      }

      $(target).highcharts(localConf);
    });
  };

  return this;
};
