// jshint esversion: 6

PIKE.misc = function($) {
  'use strict';

  this.loadScript = (s, onload) => {
    const node = document.createElement('script');
    node.setAttribute('src', s);
    node.setAttribute('async', true);
    const sn = document.getElementsByTagName('script')[0];
    sn.parentNode.insertBefore(node, sn);

    if (onload) {
      node.addEventListener('load', onload, false);
    }

    return sn;
  };

  this.getUrl = (url, params, type) => {
    return $.ajax({
      method: 'get',
      url: url,
      dataType: type || 'json',
      data: params
    });
  };

  this.whenLoaded = (target) => {
    if (!target) {
      target = $(document.body);
    }
    else if (typeof target === 'string') {
      target = $(target);
    }
    else if (!target.jquery) {
      target = $(target);
    }

    target.find('[data-target]').dataTarget();
  };


  return this;
};
