// jshint esversion: 6

PIKE.googleSearch = function($) {
  const gs = $('#google-search');

  const search = function() {
    const url    = new URI('/assets/services/google-search.json');
    const form   = gs.find('form');
    const target = gs.find('#google-search-result');
    const tmpl   = gs.find('#google-search-template').html();

    form.on('submit', function() {
      const inp = form.find('input[name=q]');
      const typ = form.find('input[name=t]');
      const val = inp.val();

      url.variables.q = val;
      inp.prop('disabled', true);
      target.removeClass('populated').empty();

      if (typ.val()) {
        url.variables.t = typ.val();
      }

      $.get(url.toString())
      .then(res => {
        if (res.ok) {
          res.result.forEach(row => {
            row.htmlTitle = row.htmlTitle.replace('pike.lysator.liu.se: ', '');

            const attr = {
              link:    row['nice-link'],
              title:   row.htmlTitle,
              snippet: row.htmlSnippet,
              url:     row['nice-link']
            };

            let str = tmpl;

            Object.keys(attr).forEach(k => {
              str = str.replace('${' + k + '}', attr[k]);
            });

            target.append(str);
          });

          target.addClass('populated');
        }
      })
      .fail(err => {
        window.console.error('Error: ', err);
      })
      .always(() => {
        inp.prop('disabled', false);
      });
      return false;
    });
  };

  if (gs.length) {
    new search();
  }

  return this;
};
