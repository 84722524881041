// jshint esversion: 6

PIKE.sidemenu = function($) {
  'use strict';

  const smc = $('#side-menu-container');
  const tmc = $('#side-menu-top-container');
  const hasSideMenu = smc.length > 0;

  const cm = function() {
    if (!hasSideMenu) {
      return;
    }

    // Move to top
    if (PIKE.screenSize.lt(PIKE.SCREEN_MD)) {
      if (cm.oldState === 2) {
        return;
      }
      // window.console.log('Set sidemenu to top');
      cm.oldState = 2;
      const tc = $(`
        <div class='tmc-wrapper'>
          <div class='site-wrap site-padding-sides'>
            <div class='burger' aria-role='button'>
              <span class='the-burger'>
                <img src='/assets/img/burger.svg' alt='Menu toggle icon' />
              </span>
              <span class='the-text'>MENU</span>
            </div>
            <div class='m-container'></div>
          </div>
        </div>
      `);
      tc.find('[aria-role=button]').on('click', function() {
        tc.find('.m-container').slideToggle('fast');
        $(this).toggleClass('on');
      });
      tmc.append(tc);
      tmc.find('.m-container').append(smc.find('menu'));
    }
    // Move to side
    else {
      if (cm.oldState === 1) {
        return;
      }
      // window.console.log('Set sidemenu to side');
      cm.oldState = 1;
      smc.append(tmc.find('menu'));
      tmc.find('.tmc-wrapper').remove();
    }
  };

  cm.oldState = 1;
  PIKE.checkSideMenu = cm;

  return this;
};
