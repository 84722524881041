// jshint esversion: 6

PIKE.jqueryPlugins = function($) {
  'use strict';


  $.fn.dataTarget = function() {
    return this.each((i, el) => {
      // window.console.log('Data target on', el);
      el = $(el);
      let title = el.attr('title');
      if (!title) {
        title = 'Link will open in a new window';
      }
      else {
        title = ' (opens in new window)';
      }
      el.attr('title', title);
      el.attr('target', el.attr('data-target'));
      el.removeAttr('data-target');
    });
  };

  return this;
};
