// jshint esversion: 6
PIKE.screenSize = function($) {
  'use strict';

  PIKE.SCREEN_XS =  1;
  PIKE.SCREEN_SM =  2;
  PIKE.SCREEN_MD =  4;
  PIKE.SCREEN_LG =  8;
  PIKE.SCREEN_XL = 16;

  let screenSize;

  PIKE.screenSize.update = function() {
    const elWidth = $('#showBp').outerWidth();
    let size;

    if (elWidth === 5) size = PIKE.SCREEN_XS;
    if (elWidth === 4) size = PIKE.SCREEN_SM;
    if (elWidth === 3) size = PIKE.SCREEN_MD;
    if (elWidth === 2) size = PIKE.SCREEN_LG;
    if (elWidth === 1) size = PIKE.SCREEN_XL;

    screenSize = size;
  };

  PIKE.screenSize.lt = (what) => {
    return screenSize < what;
  };

  PIKE.screenSize.gt = (what) => {
    return screenSize > what;
  };

  PIKE.screenSize.eq = (what) => {
    return screenSize === what;
  };

  PIKE.screenSize.update();

  return PIKE;
};
