// jshint esversion: 6

PIKE.onDomReady = function($) {
  'use strict';

  PIKE.checkTopMenu = function() {
    // Mobile
    if (PIKE.screenSize.lt(PIKE.SCREEN_MD)) {
      if (PIKE.checkTopMenu.oldState === 2) {
        return;
      }

      let a = $('#burger button'),
        ul = $('#topmenu');

      ul.addClass('js').removeAttr('style');
      a.off('click').on('click', function() {
        ul.toggleClass('open').slideToggle('fast');
        a.toggleClass('open');
        return false;
      });

      PIKE.checkTopMenu.oldState = 2;
      PIKE.watchSubmenuScroll();
    }
    else {
      if (PIKE.checkTopMenu.oldState === 1) {
        return;
      }

      let a = $('#burger button'),
        ul = $('#topmenu');

      a.off('click').removeClass('open');
      ul.removeClass('open js').removeAttr('style');

      PIKE.checkTopMenu.oldState = 1;
      PIKE.watchSubmenuScroll(true);
    }
  };

  PIKE.watchSubmenuScroll = function(detach) {
    const t = $('#site-header .submenu-wrapper .site-wrap');

    if (detach) {
      t.off('scroll');
    }
    else {
      let state = 0;
      // let maxs = t[0].scrollWidth - $(window).width();
      t.on('scroll', function(e) {
        if (t.scrollLeft() > 1) {
          if (state === 1) {
            return;
          }

          $('#submenu-scroll-overflow-left').fadeIn();
          state = 1;
        }
        else {
          if (state === 0) {
            return;
          }

          $('#submenu-scroll-overflow-left').fadeOut();
          state = 0;
        }
      });
    }
  };

  // if ($('div.banner').length) {
  //   const bannerDelay = 5000;
  //   const banner = $('div.banner');
  //   const alts = 2;
  //   let counter = 0;
  //   let bannerBg;
  //   banner.addClass('alt4');
  //   bannerBg = () => {
  //     if (counter > alts) {
  //       counter = 0;
  //       banner.removeClass('alt' + alts);
  //     }
  //     else {
  //       banner.removeClass('alt' + (counter-1))
  //             .addClass('alt' + (counter));
  //       counter += 1;
  //     }

  //     setTimeout(bannerBg, bannerDelay);
  //   };

  //   // setTimeout(bannerBg, bannerDelay);
  // }

  PIKE.checkTopMenu.oldState = 1;
  PIKE.checkTopMenu();
  PIKE.checkSideMenu();
  PIKE.whenLoaded();

  $.fn.customValidity = function() {
    return this.each(function() {
      $(this).on('keyup', function() {
        if (this.validity.typeMismatch) {
          this.setCustomValidity($(this).attr('data-error-message'));
        }
        else {
          this.setCustomValidity('');
        }
      });
    });
  };

  $('input[data-error-message],' +
    'textarea[data-error-message],' +
    'select[data-error-message]').customValidity();

  return this;
};
