// jshint esversion: 6
// global ROXEN



(($) => {
  // We need to add __toolbar=0 to all ajax request when in
  // Insite Editor
  $.ajaxPrefilter(function(options, orgoptions, xhr) {
    if (window.ROXEN && window.ROXEN.page &&
        window.ROXEN.page.enable_insite_edit == 1)
    {
      options.url +=
        (options.url.indexOf('?') > -1 ? '&' : '?') +
        '__toolbar=0&__fake-toolbar=1';
    }
  });

  $(() => {
    PIKE.plugins($)
      .screenSize($)
      .sidemenu($)
      .jqueryPlugins($)
      .misc($)
      .onDomReady($)
      .googleSearch($)
      .highcharts($)
      .tablist($)
      .screenResizeEvents($, () => {
        PIKE.screenSize.update();
        PIKE.checkTopMenu();
        PIKE.checkSideMenu();
      });

    window.PIKE_IS_HERE = true;
  });
})(jQuery);
